import { APP_INITIALIZER, inject } from '@angular/core';
import {
	ConsignmentNoteDto,
	ConsignmentNoteEntityName,
	ConsignmentNoteLineDto,
	ConsignmentNoteLineEntityName,
	OutboundDeliveryOrderDocumentTypeDto,
	OutboundDeliveryOrderDocumentTypeEntityName,
	OutboundDeliveryOrderDto,
	OutboundDeliveryOrderEntityName,
	OutboundDeliveryOrderLineDto,
	OutboundDeliveryOrderLineEntityName,
	OutboundQueueDto,
	OutboundQueueEntityName
} from '@nest/domain/entities-metadata/dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

const paths = autoPathsFor<OutboundDeliveryOrderDto>();
const linePaths = autoPathsFor<OutboundDeliveryOrderLineDto>();

export const OutboundDeliveryOrderDefaultElements = fieldsToElements(paths(['createdAt', 'warehouse.name', 'documentType.name', 'documentNumber', 'state', 'buyer.name', 'lines']));
export const OutboundDeliveryOrderLineDefaultElements = fieldsToElements(
	linePaths([
		'stockItem.item.code',
		'stockItem.item.name',
		'quantity',
		'quantityNowLoaded',
		'quantityPicked',
		'quantityPickRemaining',
		'quantityChecked',
		'quantityPacked',
		'quantityDispatched'
	])
);

export function provideOutboundEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				return () => {
					entityMetadata.registerEntity({
						entityName: OutboundDeliveryOrderDocumentTypeEntityName,
						defaultElements: fieldsToElements(autoPathsFor<OutboundDeliveryOrderDocumentTypeDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: OutboundDeliveryOrderLineEntityName,
						defaultElements: OutboundDeliveryOrderLineDefaultElements
					});
					entityMetadata.registerEntity({
						entityName: OutboundDeliveryOrderEntityName,
						defaultElements: OutboundDeliveryOrderDefaultElements
					});
					entityMetadata.registerEntity({
						entityName: OutboundQueueEntityName,
						defaultElements: fieldsToElements(autoPathsFor<OutboundQueueDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: ConsignmentNoteEntityName,
						defaultElements: fieldsToElements(autoPathsFor<ConsignmentNoteDto>()(['id', 'parcelNumber']))
					});
					entityMetadata.registerEntity({
						entityName: ConsignmentNoteLineEntityName,
						defaultElements: fieldsToElements(autoPathsFor<ConsignmentNoteLineDto>()(['id', 'parcelNumber']))
					});
				};
			}
		}
	];
}
